<template>
  <div></div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {
    this.$auth.login({ target: this.$route.query.to })
  }
}
</script>